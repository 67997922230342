/* eslint-disable */
<template>
  <div></div>
  <!--<div class="sidebar-footer"></div>-->
</template>

<script>
export default {
  name: 'SidebarFooter'
}
</script>
