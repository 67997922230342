/* eslint-disable */
<template>
  <div></div>
  <!-- <div class="sidebar-header"></div> -->
</template>
<script>

export default {
  name: 'SidebarHeader'
}
</script>
